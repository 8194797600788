.user {
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  margin-bottom: 4px;
  text-align: start;
  position: relative;
}

.header {
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  text-align: center;
  align-items: center;

  svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.double,
.right,
.wrong {
  color: white;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  right: -1rem;
}

.double {
  background: #318cfb;
}

.right {
  background: #23ab8e;
}

.wrong {
  background: #db3e44;
}

.input {
  margin-bottom: 1rem;
  width: 280px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 5px;
  border-color: #3574cfc0;
}

.section {
  background: white;
  border-radius: 12px;
  padding: 0 2rem 1rem;
  text-align: center;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.spinner {
  width: 35px;
  height: 35px;
}

.wide {
  grid-area: wide;
  background-image: linear-gradient(#3574cfc0, #3574cfc0),
    url("https://media.giphy.com/media/3ohhwKhwLeELpAERaw/giphy.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.wrapper {
  display: grid;
  grid-template-areas: "wide wide";
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    max-width: unset;
    margin: 1rem;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}
