.wrapper {
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    max-width: unset;
    margin: 1rem;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
}

.label {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 4px;
  }
}

.options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.message {
  background: #336bbb20;
  padding: 8px 1rem;
  box-sizing: border-box;
  border-radius: 4px;
}

.borderBox {
  border-width: 1px;
  border-color: rgba($color: grey, $alpha: 0.3);
  border-style: solid;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
}
