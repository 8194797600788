.wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
}

.logo {
  height: 32px;
}

.logoutButton {
  width: 50px;
  height: 35px;
  padding-left: 18px;
}
