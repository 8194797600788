.imageContainer {
  width: 100%;
  margin: 16px;
  display: flex;
  justify-content: center;
}

.imageContainer img {
  border-radius: 8px;
}

.imageContainer img:hover {
  cursor: pointer;
}
