html {
  background: #d9e7fb;
}

a {
  text-decoration: none;
  color: unset;
}

body,
input {
  font-size: 16px;
  margin: 0;
  font-family: Muli, "Helvetica Neue", sans-serif;
}

button {
  border-radius: 4px;
  border: none;
  font-family: Muli, "Helvetica Neue", sans-serif;
  background: #4086ea;
  color: white;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  width: 100%;
  max-width: 240px;
}

button:hover {
  background: #336bbb;
  cursor: pointer;
}

button:disabled {
  background: #e2e4e6;
  color: #5b5b5b;
  cursor: not-allowed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
