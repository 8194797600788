.answers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.button {
  display: flex;
  width: 240px;
  margin: 2rem auto 0;
}

.wrapper {
  max-width: 800px;
  padding: 2rem 1rem;
  margin: 1rem auto;
  position: relative;
  border-radius: 1rem;
  background: white;

  h2 {
    margin: 0 auto 2rem;
    text-align: center;
  }

  @media only screen and (max-width: 1000px) {
    max-width: unset;
    margin: 1rem;
  }
}

.content {
  margin: 0 1rem;
  position: relative;
  z-index: 1;
}

.double,
.right,
.wrong {
  color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 0 auto;
  display: block;
  padding: 8px;
  box-sizing: border-box;
}

.double {
  background: #318cfb;
}

.right {
  background: #23ab8e;
}

.wrong {
  background: #db3e44;
}

.powerUpWrapper {
  display: flex;
  align-items: center;

  button {
    margin-right: 1rem;
  }
}
