.wrapper {
  color: black;
  width: 100%;
  max-width: unset;
  height: 48px;
  font-size: 16px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #4086ea;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:hover,
  &.active {
    border: 2px solid #4086ea;
    background: transparent;
  }
}

.correct {
  background: #2dd1ac;

  &:hover,
  &.active {
    border: 2px solid #4086ea;
    background: #25b999;
  }
}

.incorrect {
  background: #f5454c;

  &:hover,
  &.active {
    border: 2px solid #4086ea;
    background: #db3e44;
  }
}
